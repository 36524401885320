
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
// import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private httplclient:HttpClient) { }
  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
  //   throw new Error('Method not implemented.');
  // }

  // isLoggedIn() {

  //   var token = localStorage.getItem("token");
  //   console.log(token);
  //   return this.jwtHelper.isTokenExpired('token'); 
  // }

  isLoggedIn(){
    return !!localStorage.getItem('token');

  }

  getToken(){
 return localStorage.getItem('token');
  }
}
