<div class="loginBackground">
  <div class="container-fluid backLogin p-0">
    <div class="card m-3">
      <div class="card-body" style="background-color: #fff">
        <div class="text-center">
          <img
            width="100"
            class="loginImg"
            src="./assets/images/logo_TKE.png"
            alt=""
            style="width: 30% !important; margin-top: 40px"
          />
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="login()">
          <div class="content">
            <div class="row">
              <div class="input-field col-5 input-field">
                <select
                  name="loginType"
                  id="loginType"
                  class="input-field"
                  style="width: 243%"
                  formControlName="loginType"
                  required
                >
                  <option value="" selected disabled>Select Login Type</option>
                  <option [value]="1">Vendor</option>
                  <option [value]="2">TKEI Employee</option>
                </select>
                <small
                  class="form-text text-danger"
                  *ngIf="
                    (!f.loginType.valid &&
                      (f.loginType.dirty || f.loginType.touched)) ||
                    (submitted && f.loginType.errors)
                  "
                  >Type is required</small
                >
              </div>
              <div class="input-field">
                <input
                  type="email"
                  formControlName="emailId"
                  [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }"
                  placeholder="Email"
                  autocomplete="nope"
                />
              </div>
              <div class="input-field">
                <input
                  type="password"
                  placeholder="Password"
                  autocomplete="new-password"
                  formControlName="password"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                />
              </div>
            </div>
          </div>
          <div class="loginBtn">
            <button
              type="button"
              class="btn btn-primary btn-lg loginbtn"
              (click)="login()"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
