<!-- <div class="user-profile" style="background: url(assets/images/background/user-info.jpg) no-repeat;">
    <div class="profile-img"> <img src="assets/images/users/profile.png" alt="user"> </div>
    <div class="profile-text"><a [matMenuTriggerFor]="sdprofile" class=""> Markarn Doe <i
                class="ti-angle-down font-12 m-l-5"></i></a></div>
    <mat-menu #sdprofile="matMenu" class="mymegamenu">
        <button mat-menu-item>
            <mat-icon>settings</mat-icon> Settings
        </button>
        <button mat-menu-item>
            <mat-icon>account_box</mat-icon> Profile
        </button>
        <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon> Disable notifications
        </button>
        <button mat-menu-item>
            <mat-icon>exit_to_app</mat-icon> Sign Out
        </button>
    </mat-menu>
</div> -->


<div style="margin-top: 20px;margin-bottom: 20px;margin-left: 20px;">
    <a class="menuNames" [routerLink]="['/dashboard']">
        <!-- href="/dashboard" -->
        Dashboard
    </a>
</div>

<mat-accordion *ngIf="loginType === '2'">
    <mat-expansion-panel class="accPanel">
        <mat-expansion-panel-header>
            <mat-panel-title style="margin-left: 20px;"> Masters </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let item of arrayOfmenus">
            <a class="menuNames" [routerLink]="['/', item.state]">
                <span>{{ item.name }}</span>
            </a>
        </div>
    </mat-expansion-panel>
</mat-accordion>

<br>
<mat-accordion>
    <mat-expansion-panel class="accPanel">
        <mat-expansion-panel-header>
            <mat-panel-title style="margin-left: 20px;"> Vendor Invoice </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <a class="menuNames" [routerLink]="['/vendorInvoiceList']">
               Vendor Invoice List
            </a>
        </div>
        <div>
            <a class="menuNames" [routerLink]="['/paymentImportList']">
                Payment Import
            </a>
        </div>
    </mat-expansion-panel>
</mat-accordion>

<br>

<mat-accordion *ngIf="loginType === '2'">
    <mat-expansion-panel class="accPanel">
        <mat-expansion-panel-header>
            <mat-panel-title style="margin-left: 20px;"> Reports </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let item of arrayOfReport">
            <a class="menuNames" [routerLink]="['/', item.state]">
                <span>{{ item.name }}</span>
            </a>
        </div>
    </mat-expansion-panel>
</mat-accordion>