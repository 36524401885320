import { ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";
import { MenuItems } from "../../../shared/menu-items/menu-items";
import { RouterLink } from "@angular/router";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
  // styleUrls: ['./blood-group-master-create.component.css']
})
export class AppSidebarComponent implements OnDestroy {
  mobileQuery: MediaQueryList;
  currentIndex = 1;
  roleId: any;
  arrayOfmenus = [
    { name: "Region Master", state: "regionMasterList", icon: "insert link" },
    { name: "Branch Master", state: "branchMasterList", icon: "insert link" },
    {
      name: "Purchase Group",
      state: "purchaseGroupList",
      icon: "insert link",
    },
    { name: "Plant Master", state: "planeMasterList", icon: "insert link" },
    { name: "Department Master", state: "departmentList", icon: "insert link" },
    { name: "Line Of Business", state: "lobList", icon: "insert link" },
    {
      name: "Expensel Type",
      state: "expenselTypeMaterList",
      icon: "insert link",
    },
    { name: "Role Master", state: "roleMasterList", icon: "insert link" },
    { name: "User Master", state: "userMasterList", icon: "insert link" },
    {
      name: "Approval Matrix",
      state: "approvalMatrixMasterList",
      icon: "insert link",
    },
    { name: "Vendor Master", state: "vendorMasterList", icon: "insert link" },
    { name: "Manage PO Master", state: "managePOList", icon: "insert link" }
  ];

  arrayOfVendorInvoice = [
    {
      name: "Vendor Invoice",
      state: "vendorInvoiceListNew",
      icon: "insert link",
    },
    { name: "Payment Import", state: "paymentImportList", icon: "insert link" },
  ];

  arrayOfReport = [
    {
      name: "invoice Report",
      state: "downloadInvoiceReportComponent",
      icon: "insert link",
    },
    {
      name: "SLR Report",
      state: "SlrReportComponent",
      icon: "insert link",
    },
  ]
  private _mobileQueryListener: () => void;
  loginType: string | null;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems
  ) {
    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.loginType = localStorage.getItem("loginType");
    this.roleId = localStorage.getItem("roleId");
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  panelOpenState = false;
  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
}
