import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MasterServService } from "src/app/master/master-serv.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: [],
})
export class AppHeaderComponent implements OnInit {
  userId: any;
  model: string;
  userName: any;
  firstName: any;
  lastName: any;
  loginType: string | null;
  vendorName: any;

  constructor(private routes: Router, public masterSvc: MasterServService) {}

  ngOnInit(): void {
    this.loginType = localStorage.getItem("loginType");
    this.userId = localStorage.getItem("id");
    // console.log("loginType-->>", this.loginType);
    // console.log("userId-->>", this.userId);   
    this.masterSvc.getUserById(this.userId, this.loginType).subscribe((e: any) => {
      if (this.loginType == "1") {
        this.vendorName = e.data[0].vendor_name;
      } else {
        this.firstName = e.data.firstName;
      this.lastName = e.data.lastName;
      }    
     
      // console.log("---eeee--->", e);
      // if (e.data.firstName) {
      //   console.log("---else if--->");
      //   this.vendorName = e.data.firstName;
      // } else if (e.data.vendor_name) {
      //   console.log("---if--->");
      //   this.vendorName = e.data.vendor_name;
      // } else {
      //   console.log("---else--->");
      //   this.vendorName = "NA";
      // }
    });
  }

  signout() {
    localStorage.clear();
    this.routes.navigate(["/login"]);
    return false;
  }
}
