// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // url: "http://localhost:9001/",
  // img_url: "http://localhost:9001/",

  url: "https://tkeivendorapi.disctesting.in/",
  img_url: "https://tkeivendorapi.disctesting.in/",

  // vinayak
  // url: "http://192.168.29.234:9001/",
  // img_url: "http://192.168.29.234:9001/",
};
