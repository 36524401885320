import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterServService {

  roleData: any = [];
  model: any

  constructor(private httpClient: HttpClient) { }
  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  public getRoleMasterData(model: any) {
    return this.httpClient.post<Array<any>>(environment.url + 'api/masters/allMasters/getmasters', {
      "model": model
    }
    );
  }

  createRoleMaster(model: any, roleName: any, isActive: any) {
    return this.httpClient.post<boolean>(
      environment.url + 'api/masters/allMasters/mastercreateupdate/', {
      "model": model,
      "data": {
        "roleName": roleName,
        "isActive": isActive
      }
    });
  }

  getRoleById(id: number, model: any) {
    return this.httpClient.post(environment.url + "api/masters/allMasters/getMasterById/" + id, {
      "model": model
    })
  }

  updateRoleMaster(id: any, model: any, roleName: any, isActive: any) {
    return this.httpClient.post<boolean>(environment.url + "api/masters/allMasters/mastercreateupdate/",
      {
        "model": model,
        "id": id,
        "data": {
          "id": id,
          "roleName": roleName,
          "isActive": isActive
        }
      }
    )
  }

  public getBranchMasterData(model: any) {
    return this.httpClient.post<Array<any>>(environment.url + 'api/masters/allMasters/getmasters', {
      "model": model
    });
  }


  //added by shrikant for getting branch name by region id
  public getBranchByRegionId(regionId: any) {
    return this.httpClient.get<Array<any>>(environment.url + 'api/masters/allMasters/getBranchListByRegionId/'+ regionId);
  }
  

  updateUserMaster(id: any, FormDetails: any) {

    return this.httpClient.put<boolean>(
      environment.url + 'api/masters/userMaster/' + id, FormDetails
      // {
      // "data": {
      //   "firstName": firstName,
      //   "userName": userName,
      //   "middleName": middleName,
      //   "lastName": lastName,
      //   "mobileNumber": mobileNumber,
      //   "emailId": emailId,
      //   "address": address,
      //   "roleId": roleId,
      //   "username": username,
      //   "password": password,
      //   "regionMainid": regionVar,
      //   "branchMainid": branchVar
      // }
    // }

    );
  }

  createBranchMaster(model: any, branchName: any,regionId:any, isActive: any) {
    return this.httpClient.post<boolean>(
      environment.url + 'api/masters/allMasters/mastercreateupdate/', {
      "model": model,
      "data": {
        "branchName": branchName,
        "regionId": regionId,
        "isActive": isActive
      }
    });
  }

  updateBranchMaster(model: any, branchName: any, isActive: any, id: any,regionId:any) {
    return this.httpClient.post<boolean>(
      environment.url + 'api/masters/allMasters/mastercreateupdate/', {
      "model": model,
      "id": id,
      "data": {
        "branchName": branchName,
        "regionId": regionId,
        "isActive": isActive,
      }
    });
  }
  updateRegionMaster(model: any, regionName: any, isActive: any, id: any) {
    return this.httpClient.post<boolean>(
      environment.url + 'api/masters/allMasters/mastercreateupdate/', {
      "model": model,
      "id": id,
      "data": {
        "regionName": regionName,
        "isActive": isActive,
      }
    });
  }

  getBranchById(id: number, model: any) {
    return this.httpClient.post(environment.url + "api/masters/allMasters/getMasterById/" + id, {
      "model": model
    })
  }

  // --------------------region----------------------------

  getPurchaseDataOfIsActiveByLoginType(userId:any,loginType:any) {
    return this.httpClient.post<Array<any>>(environment.url +  'api/vendor/vendorMaster/PurchaseCodeListingAsPerLoginType/'+userId,{
      "loginType":loginType
    });
  }

  public getGrnAndMroData(userId: any) {
    return this.httpClient.post<Array<any>>(environment.url + 'api/vendor/vendorInvoice/groAndMroShowOrNot', {
      "userId": userId
    });
  }

  public getRegionMasterData(model: any) {
    return this.httpClient.post<Array<any>>(environment.url + 'api/masters/allMasters/getmasters', {
      "model": model
    });
  }

  public getVendorDataByLoginId(id: any) {
    return this.httpClient.get<Array<any>>(environment.url + 'api/vendor/vendorInvoice/regionDataByLoginId/'+ id);
  }

  public getAllDetailsFromPO(poid: any) {
    return this.httpClient.get<Array<any>>(environment.url + 'api/vendor/vendorInvoice/allDataByUsingPoId/'+ poid);
  }

  
  public getRegionMasterDataForEmp(userId: any) {
    return this.httpClient.post<Array<any>>(environment.url + 'api/vendor/vendorMaster/regionListByUserId', 
    {
     "userId":userId
    });
  }

  public getBranchMasterDataForEmp(regionId: any) {
    return this.httpClient.post<Array<any>>(environment.url + 'api/vendor/vendorMaster/branchListByRegionId', 
    {
     "regionId":regionId
    });
  }

  createRegionMaster(model: any, regionName: any, isActive: any) {

    return this.httpClient.post<boolean>(

      environment.url + 'api/masters/allMasters/mastercreateupdate/', {
      "model": model,
      "data": {
        "regionName": regionName,
        "isActive": isActive
      }
    }

    );
  }

  getRegionById(id: number, model: any) {
    return this.httpClient.post(environment.url + "api/masters/allMasters/getMasterById/" + id, {
      "model": model
    })
  }

  // ------------------------------Approval Matrix------------------------

  public getApprovalMatricesData() {
    return this.httpClient.get<Array<any>>(environment.url + 'api/masters/approvalMatrix/',
    );
  }

  createApprovalMatrixMaster(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + 'api/masters/approvalMatrix/create/', FormDetails
    );
  }
  getApprovalMatrixById(id: number, model: any) {
    return this.httpClient.get(environment.url + "api/masters/approvalMatrix/" + id,)
  }

  updateApprovalMatrixMaster(id: any, FormDetails: any) {
    return this.httpClient.put<boolean>(
      environment.url + 'api/masters/approvalMatrix/' + id, FormDetails
    );
  }



  // ---------------------------User Master------------------------

  public getUserMasterData(loginType: any) {
    return this.httpClient.post<Array<any>>(environment.url + 'api/masters/userMaster',{
      "loginType":loginType
    }); 
  }

  getRoleDataByRoleId(id: number) {
    return this.httpClient.get(environment.url + "api/masters/userMaster/roleNameByRoleId/" + id,)
  }

  createUserMaster(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + 'api/masters/userMaster/create', FormDetails);
  }


  getUserById(id: number, loginType: any) {
    return this.httpClient.post(environment.url + "api/masters/userMaster/" + id,{
      "loginType":loginType
    })
  }

  // ---------------------------Vendor Master-----------------------

  public getVendorMasterData(model: any) {
    return this.httpClient.post<Array<any>>(environment.url + 'api/masters/allMasters/getmasters', {
      "model": model
    }
    );
  }

  createVendorMaster(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + 'api/vendor/vendorMaster/create', FormDetails
    );
  }

  public getVendorData() {
    return this.httpClient.get<Array<any>>(environment.url + "api/vendor/vendorMaster/");
  }

  getVendorById(id: number) {
    return this.httpClient.get(environment.url + "api/vendor/vendorMaster/" + id, {
    })
  }
  updateVendorUser(id: any, FormDetails: any) {
    return this.httpClient.put<boolean>(environment.url + "api/vendor/vendorMaster/" + id,
      FormDetails
    );
  }
  login(FormDetails: any) {
    return this.httpClient.post<any>(environment.url + "api/auth/signIn", FormDetails);
  }
  public changeRoleMasterStatus(tableName: any, isActive: any, id: any) {
    return this.httpClient.put<Array<any>>(environment.url + 'api/masters/isActive/' + id, {
      "id": id,
      "tableName": tableName,
      "isActive": isActive
    }
    );
  }

  //purchase group 
  createPurchaseMaster(purchaseName:any, purchaseCode:any,isActive:any) {
    return this.httpClient.post<boolean>(
      environment.url + 'api/masters/purchaseGroupMaster/create/', {
        "purchaseName":purchaseName,
        "purchaseCode":purchaseCode,
        "isActive":isActive
    });
  }

  updatePurchaseMaster(id:any,purchaseName:any, purchaseCode:any,isActive:any) {
    return this.httpClient.put<boolean>(
      environment.url + 'api/masters/purchaseGroupMaster/update/'+id,{
        "purchaseName":purchaseName,
        "purchaseCode":purchaseCode,
        "isActive":isActive
    });
  }
  

  ////// vinayak shinde
  createAllMaster(model: any, data: any) {
    return this.httpClient.post<boolean>(
      environment.url + 'api/masters/allMasters/mastercreateupdate/', {
      "model": model,
      "data": data
    });
  }


  updateAllMaster(model: any, data: any, id: any) {
    return this.httpClient.post<boolean>(
      environment.url + 'api/masters/allMasters/mastercreateupdate/', {
      "model": model,
      "id": id,
      "data": data
    });
  }

  // ----------------------PO -------------------------------

  public getPOData() {
    return this.httpClient.get<Array<any>>(environment.url + 'api/po/allPo/getAllpo');
  }

  public getPODataForVendor(vendorId:any) {
    return this.httpClient.post<Array<any>>(environment.url + 'api/vendor/vendorInvoice/poListByVendorId',{
      "vendorId":vendorId
    });
  }


  createPO(formData: any) {
    return this.httpClient.post<boolean>(
      environment.url + 'api/po/allPo/importPOFile', formData);
  }
  createPaymentImport(formData: any) {
    return this.httpClient.post<boolean>(
      environment.url + 'api/vendor/paymentImport/paymentTermImportFile', formData);
  }
  createVendorMasterImport(formData: any) {
    return this.httpClient.post<boolean>( 
      environment.url + 'api/vendor/vendorMaster/vendorMasterImportFile', formData);
  }

  public getApprovalUserList(type:any,sequence:any) {
    return this.httpClient.post<Array<any>>(environment.url + 'api/vendor/vendorInvoice/nextLevelApprovalList',{
      "type": type,
      "sequence":sequence
    });
  }

  public getSequence(loginType:any,loginId:any,invoiceType:any) {
    return this.httpClient.post<Array<any>>(environment.url + 'api/vendor/vendorInvoice/getSequence',{
      "loginType":loginType,
      "loginId":loginId,
      "invoiceType":invoiceType
    });
  }


  // for expensel type master

  public getExpenselTypeMasterData(model: any) {
    return this.httpClient.post<Array<any>>(environment.url + 'api/masters/allMasters/getmasters', {
      "model": model
    });
  }

  getExpenselTypeById(id: number, model: any) {
    return this.httpClient.post(environment.url + "api/masters/allMasters/getMasterById/" + id, {
      "model": model
    })
  }

  createExpenselTypeMaster(model: any, expenselType: any,GNNumber:any, isActive: any) {
    return this.httpClient.post<boolean>(
      environment.url + 'api/masters/allMasters/mastercreateupdate/', {
      "model": model,
      "data": {
        "expenselType": expenselType,
        "GNNumber": GNNumber,
        "isActive": isActive
      }
    });
  }

  updateExpenselTypeMaster(model: any, expenselType: any, isActive: any, id: any,GNNumber:any) {
    return this.httpClient.post<boolean>(
      environment.url + 'api/masters/allMasters/mastercreateupdate/', {
      "model": model,
      "id": id,
      "data": {
        "expenselType": expenselType,
        "GNNumber": GNNumber,
        "isActive": isActive,
      }
    });
  }

}

