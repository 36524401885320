import { Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './guards/auth.guard';

import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './login/login.component';
import { AuthenticationService } from './services/authentication.service';
// import { AuthenticationService } from './services/authentication.service';

export const AppRoutes: Routes = [
   {
    path: "",
    component: LoginComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  
  // {
  //   path: "dashboard",
  //   component: DashboardComponent,
  // },

  {
    path: '',
    component: FullComponent,

    
    children: [
      {
        path: '',
        canActivate:[AuthenticationService],
        loadChildren:
          () => import('./material-component/material.module').then(m => m.MaterialComponentsModule)
      },
      {
        path: 'dashboard',
        canActivate:[AuthGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: '',
        canActivate:[AuthGuard],
        loadChildren: () =>
        import("../../src/app/master/masters.module").then(mod => mod.MastersModule),

      },
      {
        path: '',
        canActivate:[AuthGuard],
        loadChildren: () =>
        import("../../src/app/vendor/vendor-invoice.module").then(mod => mod.VendorInvoiceModule),

      },
      
    ]
  }
];