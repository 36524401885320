import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarModule, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { MasterServService } from '../master/master-serv.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;

  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private masterServService: MasterServService,
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({       
      loginType: ['', [Validators.required]],
      emailId: ['', [Validators.required]],
      password: ['', [Validators.required]]
    }, {      
    });
  }

  get f() { return this.loginForm.controls; }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.toastr.error("Error !! ", "Please Fill Data");
      return;
    }
    this.masterServService.login(this.loginForm.value).subscribe((response: any) => {
      if (response.code === 1) {
        this.toastr.success("Success !! ");
        localStorage.setItem("id", response.data[0].id);
        localStorage.setItem("roleId", response.data[0].roleId);
        console.log("userId-->>", response.data[0].id);
        console.log("roleId-->>", response.data[0].roleId);
        console.log("emailId-->>", response.data[0].emailId);
        localStorage.setItem("emailId", response.data[0].emailId);
        localStorage.setItem("token", response.accesstoken);
        localStorage.setItem("loginType", response.loginType);
        localStorage.setItem("isGRN", response.data[0].enable_grn_no);
        localStorage.setItem("isMRO", response.data[0].enable_mro_no);
        this.router.navigate(["/dashboard"]);
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      });
  }
}
