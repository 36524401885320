import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
constructor(private auth: AuthenticationService,private router:Router){}

  canActivate():boolean {

    if(this.auth.isLoggedIn()){
      
      return true;
    }
    else{
      this.router.navigate(['login']);
      return false;
    }
    
  }
  
}

// export class AuthGuard implements CanActivate {
//   constructor(private routes : Router){}
//   canActivate(
//     next: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
//       alert(localStorage.getItem('token'))
//       if(localStorage.getItem('token')!= null){
//         return true;
//           }
//           else
//           {
//             this.routes.navigate(['/login']);
//             return false;
//           }
    
//   }
// }
