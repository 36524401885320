<div style="display: flex;">
    <button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5"> <img src="assets/images/users/1.jpg" alt="user"
            class="profile-pic"> </button>
    <p *ngIf="loginType === '1'" [matMenuTriggerFor]="profile"
        style="font-size: initial;cursor: pointer;margin-right: 20px;margin-top: 5px;cursor: pointer;">{{vendorName}}</p>
    <p *ngIf="loginType === '2'" [matMenuTriggerFor]="profile"
        style="font-size: initial;cursor: pointer;margin-right: 20px;margin-top: 5px;cursor: pointer;">{{firstName}} {{lastName}}</p>
</div>
<mat-menu #profile="matMenu" class="mymegamenu">
    <button mat-menu-item (click)="signout()">
        <mat-icon>exit_to_app</mat-icon> Sign Out
    </button>
</mat-menu>